import React, { useState } from "react";
import PropTypes from "prop-types";
import api from "components/settings/api";

import ModalErrorHandler from "./ModalErrorHandler";
import { toast, Bounce } from "react-toastify";
import CustomToastMessage from "../common/CustomToastMessage";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Grid, Button } from "@mui/material";

const NewFeedback = ({ toggleFeedbackModal }) => {
  // Input field state
  const initialTeamData = {
    title: "",
    content: "",
    email: "",
    contact_phone: "0",
  };

  // Form Input State
  const [{ title, content, email, contact_phone }, setFeedbackData] =
    useState(initialTeamData);

  // Errors State
  const [showErrors, setShowErrors] = useState(false);
  const [errorsArr, setErrorsArr] = useState();

  // State updates on input
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFeedbackData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Toast message function
  const notify = () =>
    toast.info(<CustomToastMessage message="Thank you for your feedback." />, {
      autoClose: 2000,
      closeButton: true,
      hideProgressBar: false,
      transition: Bounce,
      position: "bottom-center",
    });

  // Form submission
  const handleSubmit = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }

      const payload = { title, content, email, contact_phone };

      // Send post request for signup
      const res = await api.post("/api/v1/feedback/new", payload);

      // If no validation errors were found
      if (res.data.validationErrors === undefined) {
        // Clear any errors
        setErrorsArr([]);
        // Hide the errors component
        setShowErrors(false);
        // Toggle toast message
        notify();
        // Toggle the modal
        toggleFeedbackModal();
      } else {
        // Set errors
        setErrorsArr(res.data.validationErrors.errors);
        // Show the errors component
        setShowErrors(true);
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <div className="container--modal_wrapper">
      <div className="container--modal-dark" style={{ padding: "1rem" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <h2 className="header header--modal_heading contact--us">
              Give Us Your Feedback
            </h2>
          </Grid>
          <div style={{ padding: 5 }} />
          <Grid item>
            <Button
              disableRipple={false}
              variant="text"
              className="button--modal_cancel"
              aria-label="Close modal"
              onClick={toggleFeedbackModal}
            >
              <CloseRoundedIcon fontSize="inherit" />
            </Button>
          </Grid>
        </Grid>
        <div className="container--inner_modal" style={{ padding: 0 }}>
          <form
            className="form--modal_form"
            onSubmit={handleSubmit}
            method="POST"
            aria-label="Contact us form"
          >
            {showErrors && <ModalErrorHandler errorsArr={errorsArr} />}
            <label className="label--form_label" htmlFor="email">
              Your Email Address:
              <input
                className="input input--form_input"
                type="email"
                name="email"
                value={email}
                onChange={handleInputChange}
                required
                aria-label="email"
              />
            </label>
            <label className="label--form_label" htmlFor="title">
              Subject:
              <input
                className="input input--form_input"
                type="text"
                name="title"
                value={title}
                minLength="4"
                maxLength="50"
                onChange={handleInputChange}
                required
                aria-label="title"
              />
            </label>
            <label className="label--form_label" htmlFor="content">
              Feedback:
              <textarea
                className="input input--form_input"
                id="feedbackContact"
                type="textarea"
                name="content"
                // row="10"
                // cols="1"
                value={content}
                onChange={handleInputChange}
                minLength="1"
                maxLength="600"
                required
                aria-label="content"
              />
            </label>
            <label
              className="label--form_label label--form_label-honey"
              aria-hidden="true"
            >
              Contact Me By Phone Only
              <input
                className="input input--form_input input--form_input-honey"
                type="checkbox"
                name="contact_phone"
                value="1"
                onChange={handleInputChange}
                style={{ display: "none" }}
                tabIndex="-1"
                autoComplete="off"
                aria-label="contact_phone"
              />
            </label>
            <input
              className="button button--dashboard-full_width button--dashboard-primary"
              type="submit"
              value="Submit"
              aria-label="Submit"
            ></input>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewFeedback;

NewFeedback.propTypes = {
  toggleFeedbackModal: PropTypes.func,
};
