import React, { useState, useRef } from "react";
import { CloseModal } from "styles/globalSVG";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useScreenSize from "components/customHooks/useScreenSize";
import ReactSelect from "react-select";
// import TeamPlan from "./TeamPlan";
// import api from "components/settings/api";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useAuth } from "components/context/auth";
import ModalErrorHandler from "../ModalErrorHandler";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    cursor: "pointer",
    border: "0",
    backgroundColor: "inherit"
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
  },

  reportContainer: {
    position: "fixed",
    placeContent: "center",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    zIndex: 1001,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#00000088",
  },

  mobileReportContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    zIndex: 1001,
    backgroundColor: "#00000088",
  },
  sendBtn: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    borderRadius: theme.spacing(6),
    background: "#2eb790",
    color: "#fff",
    padding: theme.spacing(1, "30px"),
    "&:hover": {
      background: "#2eb790",
    },
    marginBottom: "24px",
  },
  modalEnd: {
    textAlign: "center",
  },
}));

const ReportModal = (props) => {
  const optionsBrowser = [
    {
      value: "Chrome",
      label: "Chrome",
      color: "black",
    },
    {
      value: "Firefox",
      label: "Firefox",
      color: "black",
    },
    {
      value: "Safari",
      label: "Safari",
      color: "black",
    },
    {
      value: "Opera",
      label: "Opera",
      color: "black",
    },
  ];
  const optionsPriority = [
    {
      value: "Low",
      label: "Low",
      color: "black",
    },
    {
      value: "Medium",
      label: "Medium",
      color: "black",
    },
    {
      value: "High",
      label: "High",
      color: "black",
    },
  ];

  const { reportIssue } = useAuth();

  const [showErrors, setShowErrors] = useState(false);
  const [errorsArr, setErrorsArr] = useState();

  const sendReport = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }

      const payload = {
        name,
        email,
        browser,
        priority,
        description,
        file,
      };

      const res = await reportIssue(payload);
      if (res.data.validationErrors !== undefined) {
        setShowErrors(true);
        setErrorsArr(res.data.validationErrors.errors);
      } else if (res.status === 200) {
        setErrorsArr([]);
        setShowErrors(false);
        setOpenReports(false);
      } else {
        setShowErrors(true);
        setErrorsArr(["Internal Server Error"]);
      }
    } catch (err) {
      if (err.message === "Request failed with status code 401") {
      } else {
        throw err;
      }
    }
  };

  const classes = useStyles();
  const styles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#2eb790",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "90%",
      margin: "5px 5%",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided, state) => {
      const defaultValues = {
        ...provided,
        color: state.data.color,
        border: "none",
        borderTop: state.data.borderTop && state.data.borderTop,
        paddingLeft: "5px",
        fontSize: "16px",
      };
      if (state.data.bgColor && state.isSelected) {
        defaultValues.backgroundColor = state.data.bgColor;
        defaultValues.color = "white";
      }
      return defaultValues;
    },
  };

  const screenLayoutType = useScreenSize();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [browser, setBrowser] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const { setOpenReports } = props;

  const [file, setFile] = useState();
  const inputFile = useRef(null);

  const closeButton = () => {
    setOpenReports(false);
  };

  const onUploadClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <div
      className={
        screenLayoutType === "smallScreen" ||
        screenLayoutType === "mediumScreen"
          ? classes.mobileReportContainer
          : classes.reportContainer
      }
    >
      <div className="report-container--modal-dark">
        <div className={classes.header}>
          <h2>
            Report Issue
          </h2>
          <button
              className={classes.closeButton}
              onClick={closeButton}
              aria-label="Close"
            >
              <CloseModal />
          </button>
        </div>
        {showErrors && <ModalErrorHandler errorsArr={errorsArr} />}
        <form onSubmit={sendReport} method="POST">
          <div
            className={
              screenLayoutType === "smallScreen" ||
              screenLayoutType === "mediumScreen"
                ? "modal-report-mobile-input-group-container"
                : "modal-report-input-group-container"
            }
          >
            <div className="modal-report-input-container">
              <p className="modal-report-title">Name</p>
              <div className="modal-report-item-content">
                <div className="modal-preview modal-preview-report">
                  <input
                    type="text"
                    value={name}
                    name="title"
                    onChange={(e) => setName(e.target.value)}
                    placeholder={"First & Last Name"}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className="modal-report-input-container">
              <p className="modal-report-title">Email</p>
              <div className="modal-report-item-content">
                <div className="modal-preview modal-preview-report">
                  <input
                    type="email"
                    value={email}
                    name="title"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={"Email"}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              screenLayoutType === "smallScreen" ||
              screenLayoutType === "mediumScreen"
                ? "modal-report-mobile-input-group-container"
                : "modal-report-input-group-container"
            }
          >
            <div className="modal-report-input-container modal-report-input-dropdown">
              <p className="modal-report-title">Browser</p>
              <ReactSelect
                styles={styles}
                options={optionsBrowser}
                onChange={(e) => setBrowser(e.value)}
                placeholder={"Select Browser Type"}
              />
            </div>
            <div className="modal-report-input-container modal-report-input-dropdown">
              <p className="modal-report-title">Priority</p>
              <ReactSelect
                styles={styles}
                options={optionsPriority}
                onChange={(e) => setPriority(e.value)}
                placeholder={"Select Priotity Level"}
                required={true}
              />
            </div>
          </div>
          <div className="modal-report-input-container">
            <p className="modal-report-title">Description</p>
            <textarea
              className="input--form_input text-area-report-modal"
              type="textarea"
              rows="7"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              required
              placeholder="Describe the issue you are facing here. Insert any screenshots or helpful images below."
            />
          </div>
          <div className="modal-report-attachment-title-container">
            {file === undefined ? (
              <>
                <div className="clickable" onClick={onUploadClick}>
                  <AttachmentIcon />
                </div>
                <input
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  ref={inputFile}
                  style={{ display: "none" }}
                />
                <p
                  className="modal-report-attachment-title clickable"
                  onClick={onUploadClick}
                >
                  Add Attachment
                </p>
              </>
            ) : (
              <>
                <p className="modal-report-attachment-title">{file.name}</p>
              </>
            )}
          </div>

          <div className={classes.modalEnd}>
            <Button
              variant="contained"
              className={classes.sendBtn}
              type="submit"
            >
              Send
            </Button>

            <p className="modal-report-title">Thank you for your feedback!</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportModal;
