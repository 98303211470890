import { useRouter } from "next/router";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Token from "./Token/Token";
import { IconMaker } from "../IconMaker";
import ProfileIcon from "./ProfileIcon";
// import MessageIcon from "./MessageIcon";
import { useAuth } from "components/context/auth";

const Actions = () => {
  const router = useRouter(); 
  const { user } = useAuth();

  return (
    <div className="container--nav-right">
      {/* <Button
        variant="text"
        className="muiButton-override-textTransform"
        onClick={() => router.push("/search")}
        // onClick={() => (window.location.href = "/search")}
        disableRipple={true}
        role="button"
        aria-label="Search"
      > */}

        <Button
          variant="text"
          className="muiButton-override-textTransform"
          onClick={ (e) =>  {
            if (router.pathname.includes("/search")) {
              e.stopPropagation()
            } else {
              router.push("/search");
            }
          }}
          disableRipple={true}
          role="button"
          aria-label="Search"
        >
        <Box mr={1} mt={0.5}>
          <IconMaker name="search-icon" alt="Search icon" role="img"/>
        </Box>
        <Typography>
          <span className="nav-right_search">Search</span>
        </Typography>
      </Button>
      <Token />
      {/* <MessageIcon/> */}
      <ProfileIcon />
      <span
        className="nav-right_navName"
        onClick={() => router.push("/profile")}
        tabIndex={0}
        role="link"
        aria-label="Profile"
      >
        {(user?.fullname.split(" ")[0])}
      </span>
    </div>
  );
};

export default Actions;
