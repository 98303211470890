const TwitterIcon = () => {
  return (
    <img
      className="img--footer_social_icon"
      src={`/svg/twitter.svg`}
      alt="twitter"
    />
  );
};

export default TwitterIcon;
