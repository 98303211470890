import PropTypes from "prop-types";

const CustomToastMessage = ({ message }) => {
  return (
    <div className="container--alert_message_wrapper" role="alert" aria-live="assertive" >
      <p className="text--alert_message">{message}</p>
    </div>
  );
};

export default CustomToastMessage;

CustomToastMessage.propTypes = {
  message: PropTypes.string,
};
