import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Link from "next/link";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    position: "relative",
    width: "100%",
  },
  appBar: {
    background: theme.palette.white,
    boxShadow: "none",
    width: "100%",
    maxWidth: "1000px",
    right: "unset",
    top: "unset",
    zIndex: 10,
    "& .MuiToolbar-root": {
      padding: "0px 16px",
    },
  },
  icon: {
    color: "grey",
    padding: "0px 10px",
    background: "#fff !important",
    "& .close": {
      color: "#000",
    },
  },
  avatarComponent: {
    display: "flex",
    alignItems: "center",
    flex: 1
  },
  typography: {
    color: theme.palette.teamMate.black,
    fontWeight: 550,
    width: "100%",
    marginLeft: theme.spacing(1.5),
    textTransform: "capitalize",
  },
  teamChatHeader: {
    fontWeight: 550,
    color: "#000",
    textTransform: "capitalize",
    marginLeft: theme.spacing(0),
  },
}));

export default function MobileChatHeader({ userInfo, onCloseChat }) {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <div className={classes.avatarComponent} aria-label="User information" role="presentation">
            {!userInfo?.isGroup && <Avatar src={userInfo?.picture} />}
            <Link href={`/user/${userInfo.public_user_id}`} passHref>
              <Typography
                className={`${userInfo?.isGroup ? classes.teamChatHeader : classes.typography}`}
                sx={{ fontSize: userInfo?.fullname && userInfo.fullname.length >= 23 ? '90%' : 'inherit' }}
              >
                {userInfo?.fullname?.toLowerCase()}
              </Typography>
            </Link>
          </div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onCloseChat}
            className={classes.icon}
          >
            <CloseRoundedIcon className="close" />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
