import { useState, useEffect } from 'react';

const useScreenSize = () => {
  const [screenLayoutType, setScreenLayoutType] = useState('largeScreen');
  const isClient = typeof window === 'object';

  useEffect(() => {
    const updatedLayoutSize = () => {
      if (window.innerWidth <= 760) {
        setScreenLayoutType('smallScreen');
      } else if (window.innerWidth <= 1140) {
        setScreenLayoutType('mediumScreen');
      } else {
        setScreenLayoutType('largeScreen');
      }
    };

    if (isClient) {
      // Run once
      updatedLayoutSize();

      // Add event to listen for resize
      window.addEventListener('resize', updatedLayoutSize);

      return () => {
        window.removeEventListener('resize', updatedLayoutSize);
      };
    }
  }, [isClient]);
  return screenLayoutType;
};

export default useScreenSize;
