import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import clsx from "clsx";
import dayjs from "dayjs";
import socketIOClient from "socket.io-client";
import MobileChatHeader from "./MobileChatHeader";
import ChatMessage from "./ChatMessage";
import EditorComponent from "../common/EditorComponent";
import { formatDateTime } from "../../utils/helpers";
import { CHAT_DATE_FORMAT, CHAT_ENDPOINT } from "../../utils/constants";
import Typing from "./Typing";
import { isEmpty } from "lodash";
import api, { ChatBaseUrl } from "components/settings/api";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    paddingTop: theme.spacing(8), 
    background: theme.palette.background.paper,
    position: "fixed", 
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    zIndex: 1000,
  },
  chatBox: {
    flexGrow: 1,
    overflowY: "auto",
    paddingBottom: theme.spacing(17), 
  },
  toolbar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    boxShadow: "0 -1px 3px rgba(0,0,0,0.12)",
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageEditor: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    width: '100% !important',
    padding: `0 ${theme.spacing(2)}px !important`,
  },
  editorComponent: {
    flexGrow: 1,
    width: '100% !important',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important'
  },
  sendBtn: {
    padding: theme.spacing(1),
    minWidth: 48,
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  header: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1100, 
    backgroundColor: theme.palette.background.paper,
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const MobileChatWindow = ({
  isFloating,
  onSendMessageToReceiver,
  userList,
  chatUser,
  currentUser,
  handleReciavedMessage,
  messages,
  pictures,
  updateCurrentMessageList,
  isGroup,
  onClose,
  handleSetUser,
  typingUser,
  handleUpdateTypingUser,
  ...props
}) => {
  const classes = useStyles();
  const [editor, setEditor] = useState(false);
  const chatMessageRef = useRef(null);
  const socketState = useRef();
  const [updatedMessages, setUpdatedMessages] = useState([]);
  const [editorState, updateEditorState] = useState(EditorState.createEmpty());
  const [imageSelected, setImageSelected] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    setEditor(true);
  }, []);

  useEffect(() => {
    chatMessageRef.current.scrollTop = chatMessageRef.current?.scrollHeight;
  }, [messages.length, chatUser.id, updatedMessages.length]);

  const userTyping = () => {};

  const dateTime = (messages, index) => {
    if (index === 0) {
      return formatDateTime(messages[0]?.createdAt, CHAT_DATE_FORMAT);
    } else {
      const toTime = dayjs(messages[index]?.createdAt);
      const fromTime = dayjs(messages[index - 1]?.createdAt);
      if (fromTime.isBefore(toTime, "days")) {
        return formatDateTime(
          dayjs(messages[index]?.createdAt),
          CHAT_DATE_FORMAT
        );
      } else {
        return null;
      }
    }
  };

  const renderTyping = () => {
    if (
      typingUser?.userID !== currentUser?.id &&
      typingUser?.userID === chatUser?.id
    ) {
      return <Typing />;
    }
  };

  const isAvatarRequired = (messageList, index) => {
    if (index !== 0) {
      const prevDate = dayjs(messageList[index - 1].createdAt);
      const currentDate = dayjs(messageList[index].createdAt);
      if (!prevDate.isSame(currentDate, "days")) {
        return true;
      }
    }
    return false;
  };

  const handleViewMessage = async () => {
    try {
      const updatedMessages = await Promise.all(
        messages.map(async (msg) => {
          if (!msg.seen) {
            try {
              await api.patch(`/api/v1/chat/markAsSeen?id=${msg.id}`);
              return { ...msg, seen: true };
            } catch (patchError) {
              return msg;
            }
          } else {
            return msg;
          }
        })
      );

      setUpdatedMessages(updatedMessages);
    } catch (mapError) {}
  };

  useEffect(() => {
    handleViewMessage();
  }, [handleReciavedMessage]);

  const socket = socketIOClient(ChatBaseUrl, {
    query: { chatID: chatUser.roomID },
    transports: ["websocket"],
    path: "/api/v1/socket.io",
  });

  useEffect(() => {
    socket.on("users", (data) => {
      handleSetUser(data);
    });

    socket.emit("get_all_messages", {
      roomID: chatUser?.roomID,
    });

    socket.on("send_all_messages", ({ allMessagedByRoomID }) => {
      updateCurrentMessageList(
        allMessagedByRoomID.map((m) => {
          return { ...m, roomID: chatUser.roomID };
        })
      );
    });

    socket.on("receive_message", async (data) => {
      handleReciavedMessage(data, chatUser);
    });

    socket.on("typing", async (data) => {
      handleUpdateTypingUser(data);
    });

    socketState.current = socket;

    return () => {
      socketState.current.disconnect();
    };
  }, [chatUser.roomID]);

  // to send a message
  const sendMessage = (message) => {
    const contentState = editorState?.getCurrentContent();
    const plainText = contentState.getPlainText().trim();
    const postContent = draftToHtml(convertToRaw(contentState));

    if (plainText || (imageSelected && uploadedImageUrl)) {
      const messageData = {
        senderID: currentUser?.id,
        receiverID: chatUser?.id,
        message: message,
        imageUrl: uploadedImageUrl,
      };

      socket.emit("send_message", messageData);
      //console.log("Message sent:", messageData);

      updateEditorState(EditorState.createEmpty());
      setImageSelected(null);
      setUploadedImageUrl(null);
      setMessageSent(true);
      onSendMessageToReceiver(chatUser.roomID);
    }
  };

  // Prevent body from scrolling when chat is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <React.Fragment>
      <div className={classes.header}>
        <MobileChatHeader
          userInfo={chatUser}
          isFloating={isFloating}
          onCloseChat={onClose}
        />
      </div>
      <Container
        className={clsx(
          classes.chatContainer,
          isFloating && classes.floatingChatContainer
        )}
        role="main"
      >
        <div className={classes.chatBox}>
          <div
            className={clsx(
              classes.messageList,
              isFloating && classes.floatingMessageList
            )}
            ref={chatMessageRef}
            aria-live="polite"
            role="log"
          >
            {updatedMessages.map((item, index) => (
              <ChatMessage
                key={index}
                dateTime={() => dateTime(updatedMessages, index)}
                avatarRequired={
                  index === 0 ||
                  updatedMessages[index - 1].senderID !== item.senderID ||
                  isAvatarRequired(updatedMessages, index)
                }
                currentUserId={currentUser.id}
                senderPic={
                  isGroup ? pictures[item?.senderID] : chatUser?.picture
                }
                receiverPic={currentUser?.picture}
                messageContent={item}
                userList={userList}
              />
            ))}
            {!isEmpty(typingUser) && renderTyping()}
          </div>
          <div
            className={clsx(
              classes.toolbar,
              isFloating && classes.floatingToolbar
            )}
          >
            <Paper
              elevation={0}
              className={clsx(
                classes.messageEditor,
                isFloating && classes.floatingMessageEditor
              )}
            >
              {editor && (
                <EditorComponent
                  onSendMessage={sendMessage}
                  isFloating={isFloating}
                  onUserTyping={userTyping}
                  aria-label="Message editor"
                  editor={editorState}
                  updateEditor={updateEditorState}
                  imageSelected={imageSelected}
                  setImageSelected={setImageSelected}
                  uploadedImageUrl={uploadedImageUrl}
                  setUploadedImageUrl={setUploadedImageUrl}
                  messageSent={messageSent}
                  setMessageSent={setMessageSent}
                />
              )}
            </Paper>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default MobileChatWindow;
