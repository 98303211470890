import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { GlobalContext } from "../context/GlobalContext";
import Logo from "./nav/Logo";
import { useMessageContextProvider } from "../context/MessageContext";
import Menu from "./nav/Menu";
import Token from "./nav/Token/Token";
import TokenMenu from "./nav/Token/TokenMenu";
import { useAuth } from "../../components/context/auth";

const HeaderMobile = ({ variant = "withButtons" }) => {
  const router = useRouter();
  const { user, logout } = useAuth();
  const appState = useContext(GlobalContext);
  const { toggleModal, setModal, currentUser } = appState;
  const [navTheme, setNavTheme] = useState("light");
  const [navPos, setNavPos] = useState("-fixed");
  const { isOpenMobileChat, toggleMobileChat, showMobileNav, setShowMobileNav } = useMessageContextProvider();

  const openModal = (modalType) => {
    setModal(modalType);
    toggleModal();
  };

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
  };

  useEffect(() => {
    if (router.route === "/" && navTheme === "dark") {
      setNavTheme("light");
      setNavPos("-fixed");
    }

    if (router.route !== "/" && navTheme === "light") {
      setNavTheme("dark");
      setNavPos("");
    }
  }, [router.route, navTheme]);

  const sendLogoutRequest = () => {
    logout();
  };

  const openMessage = () => {
    toggleMobileChat(!isOpenMobileChat);
  };

  if (user) {
    return (
      <>
        <header className={`header-${navTheme}`}>
          <div className={`container--header${navPos}`}>
            <Logo />
            <div className="headerSection">
              <nav className="container--mobile_nav">
                <Token />
                <button
                  className="button button--mobile_nav"
                  onClick={toggleMobileNav}
                  aria-label="menu button"
                >
                  <div
                    id="nav-icon1"
                    tabIndex={0}
                    onKeyDown={toggleMobileNav}
                    role="button"
                    onClick={toggleMobileNav}
                    className={showMobileNav ? "open" : ""}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </nav>
            </div>
          </div>
        </header>
        <TokenMenu />
        <Menu
          showMobileNav={showMobileNav}
          toggleNav={toggleMobileNav}
          sendLogoutRequest={sendLogoutRequest}
          openModal={openModal}
          variant="loggedIn"
          openMessage={openMessage}
          serverUserData={user}
        />
      </>
    );
  } else {

    return (
      <>
        <header className={`container--header${navPos} header-${navTheme}`}>
          <Logo />
          <nav className="container--mobile_nav">
            {variant === "withButtons" && (
              <>
                <button
                  className="button button--mobile_nav"
                  onClick={toggleMobileNav}
                  aria-label="menu button"
                >
                  <div
                    id="nav-icon1"
                    onClick={toggleMobileNav}
                    className={showMobileNav ? "open" : ""}
                    tabIndex={0}
                    onKeyDown={toggleMobileNav}
                    role="button"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </>
            )}
          </nav>
        </header>
        <Menu
          showMobileNav={showMobileNav}
          toggleNav={toggleMobileNav}
          sendLogoutRequest={sendLogoutRequest}
          openModal={openModal}
          variant="loggedOut"
          serverUserData={currentUser}
          openMessage={openMessage}
        />
      </>
    );
  }
};

HeaderMobile.propTypes = {
  serverUserData: PropTypes.object,
};

export default HeaderMobile;
