import React, { useEffect, useRef, useState } from "react";
import ChatWindow from "../chat/ChatWindow";
import isEmpty from "lodash.isempty";
import { Hidden } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MobileChatWindow from "../chat/MobileChatWindow";
import { useMessageContextProvider } from "../context/MessageContext";

const useStyles = makeStyles(() => ({
  chatWindowContainer: {
    width: "420px",
    maxWidth: "420px",
    height: "520px",
    maxHeight: "520px",
    boxShadow: "1px 1px 8px 1px rgb(0 0 0 / 25%)",
  },
}));

const FloatingUser = ({
  activeUserList,
  updateActiveUserList,
  updateSearchedUserList,
  currentChatUser,
  currentUser,
  isGroup,
  userPic,
  onCloseUser,
  notificationUser,
  onNotification,
  handleViewMessage
}) => {
  const classes = useStyles();

  const [currentMessageList, updateCurrentMessageList] = useState([]);
  const [messageList, setMessageList] = useState({});
  const [messages, updateMessages] = useState({});
  const [typingUser, updateTypingUser] = useState({});
  const [messageActiveUserList, updateMessageActiveUserList] =
    useState(activeUserList);
  const [, setRoomID] = useState(null);
  const { activeUserToMessage } = useMessageContextProvider();

  useEffect(() => {
    if (currentChatUser)
      setRoomID([currentChatUser?.id, currentUser.id].sort().toString());
    if (activeUserToMessage)
      setRoomID([activeUserToMessage?.id, currentUser.id].sort().toString());
  }, [currentChatUser, activeUserToMessage]);

  const [messageUpdate, setMessageUpdate] = useState({});

  const notificationRef = useRef([]);

  useEffect(() => {
    notificationRef.current = notificationUser;
  }, [notificationUser]);

  useEffect(() => {
    if (!isEmpty(messageUpdate)) {
      updateActiveUserListInner(messageUpdate);
    }
  }, [messageUpdate]);

  const updateActiveUserListInner = ({ data, user }) => {
    const updatedUserList = [];
    if (
      activeUserList.find((messageUser) => messageUser.roomID === data?.roomID)
    ) {
      if (activeUserList[0].roomID !== data?.roomID) {
        activeUserList.map((user) => {
          if (user.roomID === data?.roomID) {
            user.messageTime = new Date();
            updatedUserList.unshift(user);
          } else {
            updatedUserList.push(user);
          }
        });
        updateSearchedUserList(updatedUserList);
      } else {
        updateSearchedUserList(activeUserList);
      }
    } else {
      updateActiveUserList([...activeUserList, user]);
    }
  };

  const handleReciavedMessage = (data, user) => {
    data.roomID = [data.senderID, data.receiverID].sort().toString();
    const tempNotificationUser = [...notificationRef.current];
    if (!tempNotificationUser.includes(notificationRef.current)) {
      if (data.senderID !== currentUser.id) {
        tempNotificationUser.push(data?.senderID);
        onNotification(data?.senderID);
      }
      setMessageUpdate({ data, user });
    }
    updateMessages(data);
  };

  useEffect(() => {
    if (currentMessageList.length) {
      const tempMessageList = { ...messageList };
      tempMessageList[currentMessageList[0]?.roomID] = currentMessageList;
      setMessageList(tempMessageList);
    }
  }, [currentMessageList]);

  useEffect(() => {
    if (!isEmpty(messages)) {
      const tempMessageList = { ...messageList };
      if (messageList && messageList[messages.roomID]) {
        tempMessageList[messages.roomID].push(messages);
      } else {
        tempMessageList[messages.roomID] = [];
        tempMessageList[messages.roomID].push(messages);
      }
      setMessageList(tempMessageList);
    }
  }, [messages]);

  const sendMessage = (roomID) => {
    let updatedUserList = [];
    if (activeUserList[0].roomID !== roomID) {
      activeUserList.map((user) => {
        if (user.roomID === roomID) {
          user.messageTime = new Date();
          updatedUserList.unshift(user);
        } else {
          updatedUserList.push(user);
        }
      });
      updateSearchedUserList(updatedUserList);
    }
  };

  const handleUpdateTypingUser = (data) => {
    updateTypingUser(data);
    setTimeout(() => {
      updateTypingUser({});
    }, 1000);
  };

  if (!isEmpty(currentChatUser)) {
    return (
      <React.Fragment>
        <Hidden mdDown>
          <div className={classes.chatWindowContainer} role="region" aria-label="chat window">
            <ChatWindow
              onSendMessageToReceiver={sendMessage}
              pictures={userPic}
              handleUpdateTypingUser={handleUpdateTypingUser}
              handleSetUser={() => {}}
              isGroup={isGroup}
              messages={messageList[currentChatUser.roomID] || []}
              chatUser={currentChatUser}
              currentUser={currentUser}
              userList={activeUserList}
              updateCurrentMessageList={updateCurrentMessageList}
              isFloating={true}
              onClose={onCloseUser}
              handleReciavedMessage={handleReciavedMessage}
              typingUser={typingUser}

            />
          </div>
        </Hidden>
        <Hidden mdUp>
          <MobileChatWindow
            onSendMessageToReceiver={sendMessage}
            pictures={userPic}
            handleUpdateTypingUser={handleUpdateTypingUser}
            handleSetUser={() => {}}
            isGroup={isGroup}
            messages={messageList[currentChatUser.roomID] || []}
            chatUser={currentChatUser}
            currentUser={currentUser}
            userList={activeUserList}
            updateCurrentMessageList={updateCurrentMessageList}
            isFloating={true}
            onClose={onCloseUser}
            handleReciavedMessage={handleReciavedMessage}
            typingUser={typingUser}
          />
        </Hidden>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default FloatingUser;
