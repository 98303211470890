import { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";

const useUserLogStatus = () => {
  // This context comes from the GlobalState
  const appState = useContext(GlobalContext);
  const { currentUser } = appState;

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Do the currentUser exist and has it loaded in.
    if (currentUser) {
      // Check if the object has the isSessionValid key
      if (Object.prototype.hasOwnProperty.call(currentUser, "isSessionValid")) {
        // Check if the key has a true value
        if (currentUser.isSessionValid) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [currentUser]);

  return isLoggedIn;
};

export default useUserLogStatus;
