import React from "react";
import { makeStyles } from'@mui/styles';

const useStyles = makeStyles((theme) => ({
  wave: {
    position: "relative",
    textAlign: "center",
    width: "100px",
    height: "50px",
    marginTop: '10px',
    animation: `$waveAnimation 3000ms ${theme.transitions.easing.easeInOut}`
  },
  dot: {
    display: "inline-block",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    marginRight: "3px",
    background: "#303131",
    animation: "wave 1.3s linear infinite",
    "&:nth-child(2)": {
      animationDelay: "-1.1s",
    },
    "&:nth-child(3)": {
      animationDelay: "-0.9s",
    },
  },
  "@keyframes waveAnimationve": {
    "0%, 60%, 100%": {
      transform: "initial",
    },
    "30%": {
      transform: "translateY(-15px)",
    },
  },
}));

const Typing = () => {
  const classes = useStyles();

  return (
    <div className={classes.wave}>
      <span className={classes.dot}></span>
      <span className={classes.dot}></span>
      <span className={classes.dot}></span>
    </div>
  );
};

export default Typing;
