import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GlobalContext } from "../context/GlobalContext";
import Logo from "./nav/Logo";
import Actions from "./nav/Actions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useAuth } from "components/context/auth";

const TokenMenu = dynamic(() => import("./nav/Token/TokenMenu"), {
  ssr: false,
});

const Header = ({ variant = "withButtons", altHeaderFooter }) => {
  // This context comes from the GlobalState

  const appState = useContext(GlobalContext);
  const { toggleModal, setModal } = appState;

  const { user, logout } = useAuth();

  const [navTheme, setNavTheme] = useState("light");
  const [navPos, setNavPos] = useState("-fixed");
  const [navDarkClass, setNavDarkClass] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const openModal = (modalType) => {
    setModal(modalType);

    toggleModal();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (user) {
    return (
      <>
        <header
          className={
            "header-base" +
            (scrollPosition > 0 || altHeaderFooter ? ` header-${navTheme}` : "")
          }
        >
          <div className="centered-container">
            <div className={`container--header${navPos}${navDarkClass}`}>
              <Logo />
              <Actions />
              <Button
                className="header--logout_button"
                onClick={logout}
                disableRipple={true}
                aria-label="Logout"
              >
                Logout
              </Button>
            </div>
          </div>
        </header>
        <TokenMenu />
      </>
    );
  } else {
    return (
      <div className={`header_wrapper-${navTheme}`}>
        <header
          className={
            `container--header${navPos}${navDarkClass} header-base` +
            (scrollPosition > 500 ? ` header-${navTheme}` : "")
          }
          style={{ display: "block" }}
        >
          <div
            className="centered-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Logo />
            <nav className="container--nav-right" role="navigation" aria-label="Primary">
              {variant === "withButtons" && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx(
                      "muiButton-override-textTransform",
                      "header--signup-signin_buttons"
                    )}
                    type="button"
                    onClick={() => openModal("signup")}
                    aria-label="Sign Up"
                  >
                    <span>
                      <strong>Sign Up</strong>
                    </span>
                  </Button>
                  <Box mx={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={clsx(
                        "muiButton-override-textTransform",
                        "header--signup-signin_buttons"
                      )}
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => openModal("login")}
                      aria-label="Sign In"
                    >
                      Sign In
                    </Button>
                  </Box>
                </>
              )}
            </nav>
          </div>
        </header>
      </div>
    );
  }
};

export default Header;

Header.propTypes = {
  serverUserData: PropTypes.object,
};
