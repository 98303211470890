import React, { useContext, useEffect, useCallback } from "react";
import { GlobalContext } from "../../../../components/context/GlobalContext";
import api from "../../../../components/settings/api.js";
import dynamic from "next/dynamic";

const TokenStoreIcon = dynamic(() => import("./TokenStoreIcon.js"));
const NavToken = dynamic(() => import("./NavTokenIcon"));

const Token = () => {
  const appState = useContext(GlobalContext);
  const { navTeamCredits, setNavTeamCredits, toggleTokenMenu } = appState;

  const handleAction = (e) => {
    e.stopPropagation();
    toggleTokenMenu();
  };

const NavfetchTeamCredits = useCallback(async () => {
        const res = await api.get("/api/v1/teams/credits");
        setNavTeamCredits(res.data.tokenBalance);
      },
    [setNavTeamCredits]
  );

  useEffect(() => {
    NavfetchTeamCredits();
  }, [NavfetchTeamCredits, navTeamCredits]);

  return (
    <div
      onClick={(e) => handleAction(e)}
      onKeyDown={(e) => handleAction(e)}
      className="navbar--container_token"
      role="button"
      tabIndex="0"
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span className="navbar--tokens">{navTeamCredits}</span>
      </div>
      <div className="navbar--button_wrapper">
        <span>
          <div className="navbar--increment_icon">
            <TokenStoreIcon />
          </div>
        </span>
        <span className="navbar_ticket_shape">
          <NavToken />
        </span>
      </div>
    </div>
  );
};

export default Token;
