import { useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContext";
import PropTypes from "prop-types";
import Header from "../common/Header";
import HeaderMobile from "../common/HeaderMobile";
import ModalController from "../modals/ModalController";
import Footer from "../common/Footer";
import CookieConsentDialog from "./CookieConsentDialog";
import { useAuth } from "components/context/auth";
import FloatingChat from "components/FloatingChat/FloatingChat";

const Layout = (props) => {
  const appState = useContext(GlobalContext);
  const { isModalOpen } = appState;
  const { user } = useAuth();

  useEffect(() => {
    //flag to check if there was a signup before
    const trackedCheck = localStorage.getItem("tracked");
    if (trackedCheck === "true") return;

    const currentURL = window.location.toString();
    //Track only items from teammateme.com site
    if (!currentURL.includes("www.teammateme.com")) return;

    //Used for when the user logs into the home page
    //Or the thankyou page
    //Thankyou page needs to be excluded because this layout wraps it
    //Home page needs to be excluded so we can capture variant name
    if (currentURL === "https://www.teammateme.com/") return;
    if (currentURL.includes("thankyou")) return;

    //Check to see if UTM paramters are added by google campaigns
    if (currentURL.includes("?utm")) return;

    //Capturing the URL that the user signs up from
    localStorage.setItem("variant", currentURL);
  }, [user]);

  return (
    <>
      {user && <FloatingChat />}
      <div className="container--page_layout">
        {isModalOpen && <ModalController />}

        <div id="mobile" aria-label="Mobile">
          <HeaderMobile variant={props.headerVariant} />
        </div>
        <div id="desktop" aria-label="Desktop Header">
          <Header
            variant={props.headerVariant}
            altHeaderFooter={props.altHeaderFooter}
          />
        </div>
        <main role="main" className="container--main-home">
          {props.children}
        </main>
        <Footer />
        <CookieConsentDialog />
      </div>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  headerVariant: PropTypes.string,
};
