import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";

import dynamic from "next/dynamic";
const LoginForm = dynamic(() => import("./LoginForm"));
const SignupForm = dynamic(() => import("./SignupForm"));
const PasswordReset = dynamic(() => import("./PasswordReset"));

const ModalController = () => {
  // This context comes from the GlobalContext Wrapper Component
  const modalProporties = useContext(GlobalContext);
  const { modalToDisplay } = modalProporties;

  if (modalToDisplay === "login") {
    return <LoginForm />;
  } else if (modalToDisplay === "signup") {
    return <SignupForm />;
  } else if (modalToDisplay === "password") {
    return <PasswordReset />;
  }
};

export default ModalController;
