import PropType from "prop-types";

const ModalErrorHandler = ({ errorsArr }) => {
  //return an array of messages
  let getMessageString = errorsArr.map(({ msg }) => msg);
  //remove duplicated messages
  let errorMessages = [...new Set(getMessageString)];

  const errorsList = errorMessages.map((msg) => (
    <li key={msg} className="item item--modal_error_item">
      {`${msg}`}
    </li>
  ));

  return <ul className="list list--modal_error_list">{errorsList}</ul>;
};

export default ModalErrorHandler;

ModalErrorHandler.propTypes = {
  errorsArr: PropType.array,
};
