import { Avatar, Badge, ListItem, ListItemAvatar } from "@mui/material";
import { makeStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import { DATE_CHAT, TIME_CHAT } from "../../utils/constants";

import { formatDateTime } from "../../utils/helpers";

const useStyles = makeStyles((theme) => ({
  userList: {
    marginTop: "25px",
    marginBottom: "50px",
  },
  userListItem: {
    cursor: "pointer",
    paddingLeft: "20px",
    "&:hover": {
      background: theme.palette.teamMate.gradientGreen,
      borderRadius: theme.spacing(1),
    },
  },
  notificationUserListItem: {
    cursor: "pointer",
    paddingLeft: "20px",
    backgroundColor: theme.palette.teamMate.gradientGreen,
    "&:hover": {
      background: theme.palette.teamMate.gradientGreen,
      borderRadius: theme.spacing(1),
    },
  },
  activeUser: {
    background: theme.palette.teamMate.gradientGreen,
  },
  userNameContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  userName: {
    fontSize: "14px",
    width: "70%",
    maxWidth: "70%",
    textTransform: "capitalize",
    color: "rgba(56, 56, 56, )",
  },
  notificationUserName: {
    fontSize: "14px",
    width: "70%",
    maxWidth: "70%",
    textTransform: "capitalize",
    fontWeight: 500,
    color: "rgba(108, 117, 125,)",
  },
  messageTime: {
    color: "rgba(56, 56, 56, .8)",
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .messageCount": {
      display: "flex",
      backgroundColor: theme.palette.primary.main,
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid ${theme.palette.primary.contrastText}`,
      borderRadius: "50%",
      fontSize: "10px",
      height: "14px",
      width: "14px",
    },
  },
  notificationMessageTime: {
    color: "rgba(108, 117, 125)",

    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontWeight: 600,
    "& .messageCount": {
      display: "flex",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid ${theme.palette.primary.contrastText}`,
      borderRadius: "50%",
      fontSize: "10px",
      height: "14px",
      width: "14px",
    },
  },
}));

const ChatUsers = ({
  currentUserId,
  currentChatUserId,
  handleCallBack,
  chatUsers,
  notificationUser,
}) => {
  const classes = useStyles();

  const getDate = (date) => {
    if (date === undefined) {
      return null;
    }
    if (dayjs().isSame(date, "day")) {
      return formatDateTime(date, TIME_CHAT);
    } else {
      return formatDateTime(date, DATE_CHAT);
    }
  };

  return (
    <div className={classes.userList} role="list">
      {chatUsers?.map((user) =>
        user.id !== currentUserId && user.id !== null ? (
          <ListItem
            key={user?.id}
            alignItems="center"
            className={clsx(
              classes.userListItem,
              user.id === currentChatUserId ? classes.activeUser : "",
            )}
            onClick={() => handleCallBack(user)}
          >
            <ListItemAvatar
              className={classes.avatar}
              style={{ justifyContent: "flex-start" }}
              aria-label={`${user.fullname}'s avatar`}
            >
              {notificationUser?.includes(user.id) ? (
                <StyledBadge
                aria-label="avatar badger"
                  // overlap=""
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  variant="dot"
                >
                  <Avatar alt={user.fullname} src={user?.picture} />
                </StyledBadge>
              ) : (
                <Avatar alt={user.fullname} src={user?.picture} />
              )}
            </ListItemAvatar>
            {notificationUser?.includes(user.id) ? (
              <div className={classes.userNameContainer}>
                <div className={classes.notificationUserName}>
                  {user.fullname?.toLowerCase()}
                </div>
                <div className={classes.notificationMessageTime}>
                  <div>{getDate(user?.messageTime)}</div>
                </div>
              </div>
            ) : (
              <div className={classes.userNameContainer}>
                <div className={classes.userName}>
                  {user.fullname?.toLowerCase()}
                </div>
                <div className={classes.messageTime}>
                  <div>{getDate(user?.messageTime)}</div>
                </div>
              </div>
            )}
          </ListItem>
        ) : null,
      )}
    </div>
  );
};

export default ChatUsers;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#00a2ff",
    color: "#006eff",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);
