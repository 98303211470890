const YoutubeIcon = () => {
  return (
    <img
      className="img--footer_social_icon"
      src={`/svg/youtubeicon.svg`}
      alt="youtube"
    />
  );
};

export default YoutubeIcon;
