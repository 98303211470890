import Router from "next/router";
import { useAuth } from "components/context/auth";
import { IconMaker } from "../IconMaker";
import { Button } from "@mui/material";

const Menu = ({
  toggleNav,
  sendLogoutRequest,
  openModal,
  variant,
  openMessage,
  showMobileNav,
}) => {

  const { user } = useAuth();

  const changePage = (pageName) => {
    toggleNav();
    Router.push(`/${pageName}`);
  };

  const handleLogout = () => {
    toggleNav();
    sendLogoutRequest();
  };

  const showModal = (type) => {
    toggleNav();
    openModal(type);
  };

  return (
    <div className={`hamburger-Navbar ${showMobileNav && "showNavbar"}`} role="navigation">
      <div className="hamburger-Menu">
        <div className="space-10" />
        {variant === "loggedIn" ? (
          <>
            <Button
              onClick={() => changePage("search")}
              className="hamburger-Button"
              aria-label="Search"
            >
              <IconMaker name="search-mobile" alt="search" />
              <div className="space-10" />
              <div className="hamburger-Typography">Search</div>
            </Button>
            <Button onClick={openMessage} className="hamburger-Button" aria-label="Message">
              <IconMaker name="message" alt="message" />
              <div className="space-10" />
              <div className="hamburger-Typography">Message</div>
            </Button>
            <Button
              onClick={() => changePage("profile")}
              className="hamburger-Button"
              aria-label="Profile"
            >
              <img
                className="hamburger-ProfileIcon"
                src={user ? user.picture : ""}
                alt={user?.fullname}
              />
              <div className="space-10" />
              <div className="hamburger-Typography">{user?.fullname}</div>
            </Button>
            <Button
              onClick={handleLogout}
              className="hamburger-Button bordered"
              aria-label="Log out"
            >
              <div className="hamburger-Typography">Log out</div>
            </Button>
          </>
        ) : (
          <>
            <button
              onClick={() => showModal("signup")}
              className="hamburger-Button bg-green bordered"
              aria-label="Sign up"
            >
              <div className="hamburger-Typography color-white">Sign up</div>
            </button>
            <button
              onClick={() => showModal("login")}
              className="hamburger-Button bordered"
              aria-label="Log in"
            >
              <div className="hamburger-Typography">Sign in</div>
            </button>
          </>
        )}
        <div className="space-10" />
      </div>
    </div>
  );
};

export default Menu;
