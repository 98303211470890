import { useAuth } from "components/context/auth";
import Link from "next/link";
import LogoIcon from "../Icons/LogoIcon";

const Logo = () => {
  const { user } = useAuth();
  return (
    (<Link href={user ? "/home" : "/"} prefetch={false} title="Go to home page">

      <div className="container--logo_wrapper" role="link" aria-label="Go to home page">
        <LogoIcon />
        <img
          className="link link--pagenav-logo"
          id="small"
          src={`/logo-text-small.png`}
          alt="logo small screen"
          width="107px"
          height="13px"
        />
        <img
          id="big"
          className="link link--pagenav-logo"
          src={`/logo-text-large.png`}
          alt="logo big screen"
          width="161px"
          height="19px"
        />
      </div>

    </Link>)
  );
};

export default Logo;
