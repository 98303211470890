import dayjs from "dayjs";
var linkify = require("linkify-it")();

export const formatDateTime = (date = new Date(), formater) => {
  return dayjs(date).format(formater);
};

// Reload full tlds list & add unofficial `.onion` domain.
linkify
  .tlds(require("tlds")) // Reload with full tlds list
  .tlds("onion", true) // Add unofficial `.onion` domain
  .add("git:", "http:") // Add `git:` protocol as "alias"
  .add("ftp:", null) // Disable `ftp:` protocol
  .set({ fuzzyIP: true }); // Enable IPs in fuzzy links (without schema)

export const convertTextToLink = (text) => {
  const linkParse = linkify.match(text);
  let temp = text;
  if (linkParse) {
    linkParse?.forEach((link) => {
      temp = temp.replace(
        link.text,
        `<a href="${link.url}" target="_blank">${link.text}</a>`,
      );
    });
  } else {
    const replacePattern3 =
      /(([a-zA-Z0-9\-\_\.])+[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    temp = temp.replace(
      replacePattern3,
      '<a href="http://$1" target="_blank">$1</a>',
    );
  }
  return temp;
};

export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
