import { useState, useEffect, useRef } from "react";
import { convertToRaw, EditorState, getDefaultKeyBinding, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import dynamic from "next/dynamic";
import { EMOJIS, IMAGES } from "../../utils/constants";
import { Button } from "@mui/material";
import { makeStyles } from'@mui/styles';
import { convertTextToLink } from "../../utils/helpers";
import FileOpenIcon from '@mui/icons-material/FileOpen';

const Editor = dynamic(
  () => import("react-draft-wysiwyg").then((mod) => mod.Editor),
  { ssr: false }
);

const FLOATING_CHAT_OPTIONS = ["inline", "emoji"];

const CHAT_OPTIONS = ["inline", "list", "link", "emoji"];

const FLOATING_CHAT_INLINE_OPTIONS = ["bold", "italic", "underline"];

const CHAT_INLINE_OPTIONS = ["bold", "italic", "strikethrough", "underline"];

const useStyle = makeStyles((theme) => ({
  editorComponent: {
    width: "100%",
  },
  sendBtn: {
    height: "34px",
    marginBottom: "6px",
    right: 0,
    position: "absolute",
    borderRadius: theme.spacing(6),
    background: theme.palette.teamMate.primary.blue,
    color: theme.palette.white,
    width: theme.spacing(8 / 75),
    fontSize: "14px",
  },
  imagePreview: {
    maxWidth: '300px',
    maxHeight: '75px',
  },
  toolbarItem: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: '100%',
    border: 'none',
    background: 'none',
    padding: theme.spacing(1),
    '& img': {
      maxHeight: '43px',
      maxWidth: '30px',
      marginBottom: '5px',
      marginLeft: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
      '& img': {
        maxHeight: '35px',
        maxWidth: '25px',
      },
    },
  },
}));

const CustomToolbar = ({ onImageUploadClick }) => {
  const classes = useStyle();
  return (
      <button onClick={onImageUploadClick} className={classes.toolbarItem}>
        <img src='/blogContent/infographic/imageChatIcon.png' alt="Upload Image" />
      </button>
  );
};

const EditorComponent = ({ onSendMessage, isFloating, onUserTyping, editor, updateEditor, imageSelected, setImageSelected, uploadedImageUrl, setUploadedImageUrl, messageSent, setMessageSent}) => {
  const classes = useStyle();
  const [editorRef, updateEditorRef] = useState();
  const [resetKey, setResetKey] = useState(0); //to rerender the Editor component
  const [enterPressed, setEnterPressed] = useState(0); //to trigger useEffect=> myHandleReturn
  const inputFileRef = useRef(null);

//=== 'enter' will not miss in sending the one last character
  useEffect(()=>{
    if (enterPressed) {
      myHandleReturn(true)
    }
  },[enterPressed])

//== the cursor is back to the input field when message is sent,
  useEffect(() => {
    if (editorRef) {
      editorRef.blur();
      editorRef.focus();
    }
  }, [editorRef])

  const onEditorStateChange = (editData) => {
    updateEditor(editData);
  };

  const setEditorReference = (ref) => {
    if (ref) {
      updateEditorRef(ref);
    }
  };

  const myHandleReturn = async(sendClick) => {
    const selection = editor.getSelection();
    const key = selection.getAnchorKey();
    const content = editor.getCurrentContent();
    const block = content.getBlockForKey(key);
    const type = block.getType();
    if (
      (type !== "unordered-list-item" && type !== "ordered-list-item") || sendClick
    ) {
      
      if (imageSelected) {
        // If there is an uploaded image, send it directly
        await onSendMessage(`<img src="${uploadedImageUrl}" alt="Uploaded Image" />`);
        setResetKey(resetKey + 1); 
      } else if (
        editor.getCurrentContent().getPlainText("").trim().length !== 0
      ) {
        const content = editor?.getCurrentContent();
        let html = draftToHtml(convertToRaw(content));

        //=====This is not necessary since the Editor is re-rendered every message sending
        // await updateEditor(EditorState.createEmpty(""));

        EMOJIS.forEach((emoji) => {
          const emojiIndex = html.indexOf(emoji);
          if (emojiIndex !== -1) {
            html = html.replaceAll( emoji, `<span class="customEmoji">${emoji}</span>`);
          }
        });
        html = await convertTextToLink(html);

        // if (uploadedImageUrl) {
        //   html += `<img src="${uploadedImageUrl}" alt="Uploaded Image" />`;
        // }
        await onSendMessage(html.trim());

        //===== handled in the useEffect:
        // editorRef.blur();
        // editorRef.focus();

        setResetKey(resetKey+1) //to trigger re-rendering of Editor comp *clearing input field
      }
    }
  }

  const myKeyBindingFn = (e) => {
    setTimeout(() => {
      onUserTyping();
    }, 3000);
    if (e.keyCode === 13 /* `Enter` key */) {
      if (!e.nativeEvent.shiftKey) {
        setEnterPressed(enterPressed+1) //to trigger useEffect=>myHandleReturn()
      }
    }
    return getDefaultKeyBinding(e);
  };

  // Sending Images

  const fileTypes=["jpg","jpeg", "png", "gif", "bmp", "tiff", "ico", "eps", "psd", "svg", "webp", "jxr", "wdp"]

  const fileTypeText = () => {
    const fileExtension = imageSelected.raw.name.split('.').pop().toLowerCase();
    return fileExtension.toUpperCase();
  }

  const checkFileType = () => {
    if (imageSelected) {
    const fileExtension = imageSelected.raw.name.split('.').pop().toLowerCase();
    if (fileTypes.includes(fileExtension)) {
      return (
        <img
          src={imageSelected.preview}
          alt="Preview"
          className={classes.imagePreview}
        />
      );
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FileOpenIcon fontSize="large" />
        </div>
      );
    }
  } else {
    return null; // Return null for no image
  }
};

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      setImageSelected({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: file,
      });
      uploadImage(file, () => {
      });
    }
    e.target.value = "";
  };

  const resetFileInput = () => {
    setImageSelected(null);
    setUploadedImageUrl(null);
    inputFileRef.current.value = '';
  };

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 150; 
          const MAX_HEIGHT = 200; 
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
  
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, { type: "image/jpeg" }));
          }, "image/jpeg", 1);
        };
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadImage = async (file, callback ) => {
    const resizedFile = await resizeImage(file);
    const formData = new FormData();
    formData.append("file", resizedFile);

    try {
        const response = await fetch('https://api.teammateme.com/api/v1/chat/upload/image', {
            method: 'POST',
            body: formData,
        });
        if (response.ok) {
            const data = await response.json();

            if (data.data && data.data.imageUrl) {
                console.log("Image uploaded successfully. URL:", data.data.imageUrl);
                setUploadedImageUrl(data.data.imageUrl);
                setMessageSent(false);
               // onSendMessage(`<img src="${data.data.imageUrl}" alt="Uploaded Image" />`);
                callback();
            } else {
                console.log("Image URL not found in response.");
            }
        } else {
            console.error("Image upload failed");
            console.log("Upload Image response:",response)
        }
    } catch (error) {
        console.error("Error during image upload:", error);
    }
};

  const uploadImageCallback = (file) => {
    return new Promise(
      (resolve, reject) => {
        uploadImage(file).then(uploadedImageUrl => {
          resolve({ data: { link: uploadedImageUrl } });
        }).catch(error => {
          reject(error);
        });
      }
    );
  };

  const onImageUploadClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    if (messageSent) {
      setImageSelected(null);
      setMessageSent(false);
    }
  }, [messageSent]);

  return (
    <>
      <div className={classes.editorComponent}>
        <div className="textAreaBlock">
          <div>
          {checkFileType()} {/* Use the checkFileType function here */}
            {imageSelected && (
              <Button onClick={resetFileInput}>Remove {fileTypeText()}</Button>
            )}
          </div>
          <div className="editor" role="textbox">
            <Editor
              key={resetKey}
              editorRef={setEditorReference}
              editor={editor}
              onEditorStateChange={onEditorStateChange}
              keyBindingFn={myKeyBindingFn}
              aria-label="Text editor"
              toolbarCustomButtons={[
                <Button
                  variant="contained"
                  onClick={() => myHandleReturn(true)}
                  className={classes.sendBtn}
                  key={classes.sendBtn}
                  aria-label="Send message"
                >
                  Send
                </Button>,
                <CustomToolbar onImageUploadClick={onImageUploadClick} />
              ]}
              stripPastedStyles={true}
              toolbar={{
                options: isFloating ? FLOATING_CHAT_OPTIONS : CHAT_OPTIONS,
                inline: {
                  inDropdown: false,
                  options: isFloating ? FLOATING_CHAT_INLINE_OPTIONS : CHAT_INLINE_OPTIONS,
                },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ["unordered", "ordered"],
                },
                link: {
                  inDropdown: false,
                  options: ["link"],
                  defaultTargetOption: "_blank",
                  popupClassName: "popupClass",
                },
                emoji: {
                  icon: IMAGES.emoji,
                  emojis: EMOJIS,
                },
                image: {
                  uploadCallback: () => {},
                },
                history: { inDropdown: false },
              }}
              mention={{
                separator: " ",
                trigger: "@",
                suggestions: [""],
              }}
            />
            <input
              type="file"
              ref={inputFileRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

export default EditorComponent;