import { Avatar } from "@mui/material";
import Link from "next/link";
import { useAuth } from "components/context/auth";

const ProfileIcon = () => {
  const { user } = useAuth();
  return (
    <div className="button button--toggle-nav " aria-label="My Profile">
      {user ? (
        <div>
          <Link href="/profile" legacyBehavior>
            <Avatar
              className="avatar--profile_image"
              src={user.picture ? user.picture.replace(/^http:/, 'https:') : ''}
              alt="Avatar"
            />
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProfileIcon;
