import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import PhoneIcon from "@mui/icons-material/Phone";
import VideocamIcon from "@mui/icons-material/Videocam";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Avatar from "@mui/material/Avatar";
import Link from "next/link";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    position: "relative",
    width: "100%",
  },
  appBar: {
    background: theme.palette.white,
    boxShadow: "none",
    width: "100%",
    maxWidth: "1000px",
    right: "unset",
    top: "unset",
    zIndex: 10,
    border: `1px solid ${theme.palette.teamMate.lightGrey}`,
    "& .MuiToolbar-root": {
      padding: "0px 16px",
    },
  },
  icon: {
    color: "grey",
    padding: "0px 10px",
    background: "#fff !important",
    "& .close": {
      color: "#000",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  avatarComponent: {
    display: "flex",
    alignItems: "center",
    flex:1
  },
  typography: {
    color: theme.palette.teamMate.black,
    fontWeight: 550,
    width: "100%",
    marginLeft: theme.spacing(1.5),
    textTransform: "capitalize",
  },
  teamChatHeader: {
    fontWeight: 550,
    color: "#000",
    textTransform: "capitalize",
    marginLeft: theme.spacing(0),
  },
}));

export default function ChatHeader({ userInfo, isFloating, onCloseChat }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <div className={classes.avatarComponent} aria-label="User information" role="presentation">
            {!userInfo?.isGroup && <Avatar src={userInfo?.picture} />}
            <Link href={`/user/${userInfo.public_user_id}`} passHref>
              <Typography
                className={`${
                  userInfo?.isGroup
                    ? classes.teamChatHeader
                    : classes.typography
                }`}
                sx={{ fontSize: userInfo?.fullname && userInfo.fullname.length >= 23 ? '90%' : 'inherit' }}

              >
                {userInfo?.fullname?.toLowerCase()}
              </Typography>
            </Link>
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="show 0 new mails"
              color="inherit"
              disabled
              className={classes.icon}
              size="large"
            >
              <Badge badgeContent={0} color="secondary">
                <PhoneIcon />
              </Badge>
            </IconButton>
            <IconButton
              disabled
              aria-label="show 0 new notifications"
              color="inherit"
              className={classes.icon}
              size="large"
            >
              <Badge badgeContent={0} color="secondary">
                <VideocamIcon />
              </Badge>
            </IconButton>
            {isFloating ? (
              <IconButton
                className={classes.icon}
                onClick={onCloseChat}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                size="large"
              >
                <CloseRoundedIcon className="close" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="show 0 new notifications"
                color="inherit"
                disabled
                className={classes.icon}
                size="large"
              >
                <Badge badgeContent={0} color="secondary">
                  <AttachmentIcon />
                </Badge>
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}
