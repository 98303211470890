import React, { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Link, Typography } from "@mui/material/";
import useScreenSize from "../customHooks/useScreenSize";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "100%",
    zIndex: 9999,
    [theme.breakpoints.up('768')]: {
      height: '62px'
    }
  },
  buttonContainer: {
    placeItems: "center",
    height: "30px", // Adjust height to occupy full height of the container
  },
  mobileRoot: {
    flexGrow: 1,
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    minWidth: "100%",
    maxHeight: "7vh",
    height: "4vh",
    zIndex: 2,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "4vh", // Adjust the font size for smaller screens (e.g., below 'sm')
    },
    [theme.breakpoints.down("xs")]: {
      height: "8vh", // Adjust the font size for smaller screens (e.g., below 'sm')
    },
  },
  paper: {
    textAlign: "center",
    color: "#00A682",
    padding: "0.5rem 1rem",

    alignItems: "center",
  },
  paperSmall: {
    textAlign: "center",
    color: "#00A682",
    padding: "0.5rem 1rem",
    height: "6vh",
    alignItems: "center",
    flexDirection: "column",
  },
  theButton: {
    color: "#00A682",
    backgroundColor: "white",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.85),
    },
    borderRadius: "24px",
    height: "20px",
    padding: "12px 16px",
    // minWidth: "100px",

    [theme.breakpoints.down("md")]: {
      fontSize: "8px", // Adjust the font size for smaller screens (e.g., below 'sm')
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "6px", // Adjust the font size for smaller screens (e.g., below 'sm')
    },
  },
  text: {
    fontSize: "13px",
    fontFamily: "Open sans",
    letterSpacing: "0.15px",
    textAlign: "left",
    color: "#F4F5F2",
    lineHeight: "150%",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
  textSmall: {
    fontSize: "9px",
    fontFamily: "Open sans",
    letterSpacing: "0.15px",
    textAlign: "left",
    color: "#F4F5F2",
    lineHeight: "120%",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: "7px",
    },
  },
  toggle: {
    display: "none",
  },
  linkText: {
    fontSize: "10px",
    fontFamily: "Open sans",
    letterSpacing: "1.2px",
    color: "#0000EE",
  },
  textContainer: {
    [theme.breakpoints.down("md")]: {},
  },
  textContainerSmall: {
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },
  
}));

const CookieConsentDialog = () => {
  const [showCookie, setShowCookie] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("TeammateMe-Cookie")) {
      setShowCookie(true);
    }
  }, []);
  const classes = useStyles();
  const screenLayoutType = useScreenSize();

  const locals = () => {
    const cookie = {
      title: "teammateMe-Cookie",
      author: "TeammateMe.com",
      consent: "true",
    };
    localStorage.setItem("TeammateMe-Cookie", JSON.stringify(cookie));
  };
  const handleOkClicked = () => {
    setShowCookie(false);
    locals();
  };

  return (
    <>
      {showCookie ? (
        <div
          className={
            screenLayoutType === "smallScreen"
              ? classes.mobileRoot
              : classes.root
          }
          role="dialog"
          aria-live="polite"
          aria-labelledby="cookie-consent-title"
          aria-describedby="cookie-consent-description"
          aria-modal="true"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid
                className={
                  screenLayoutType === "smallScreen"
                    ? classes.paperSmall
                    : classes.paper
                }
                role="document"
                container
              >
                <Grid
                  item
                  xs={10}
                  className={
                    screenLayoutType === "smallScreen"
                      ? classes.textContainerSmall
                      : classes.textContainer
                  }
                >
                  <Typography
                    className={
                      screenLayoutType === "smallScreen"
                        ? classes.textSmall
                        : classes.text
                    }
                    role="contentinfo"
                    id="cookie-consent-description"
                  >
                    {/* We use cookies to ensure that we give you the best experience
                  on our website. If you continue to use this site we will
                  assume that you are happy with it. To learn more about how we
                  use cookies, please see our{" "}
                  <Link href="/cookies" className={classes.linkText}>
                    {" "}
                    Cookie policy{" "}
                  </Link>
                  . */}
                    We use cookies to improve user experience and analyze website
                    traffic. For these reasons, we may share your site usage
                    data with our analytics partners. By clicking “Accept
                    Cookies”, you consent to store on your deivice all
                    technologies described in our Cookie Policy. You can change
                    your cookie settings at any time by clicking “Cookie
                    Preferences”.
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.buttonContainer}>
                  <Button
                    variant="outlined"
                    className={classes.theButton}
                    onClick={handleOkClicked}
                    role="button"
                    aria-label="Accept cookies"
                  >
                    Accept Cookies
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CookieConsentDialog;
