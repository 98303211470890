import React, { useContext } from "react";
import api from "components/settings/api";

import StripeCheckout from "react-stripe-checkout";
import { GlobalContext } from "components/context/GlobalContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "components/context/auth";

// import STRIPE_PUBLISHABLE from "../stripe/constants/stripe";

// import PAYMENT_SERVER_URL from "../stripe/constants/server";

// const publishableKey = STRIPE_PUBLISHABLE

// Our company logo stored on Kerry's imgur account. Email me if you need something kerrysfs@gmail.com

//For some reason the stripe component wouldn't take in an image from the public folder but will take an https address only.
// This store has been coded in only 6-10 hours 3 days before my last day so I know it's rough but it's a great start to the next dev who gets to code a more robust system for our company. Thank you :-)

const TEAMMATEME_LOGO = "https://i.imgur.com/hL33Zvz.png";

// const publishableKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISH_KEY;
const publishableKey = "pk_live_51J5hFUFyp8cErJj6XR1P0CBuF7YnsQwJsj2SzKGg4RaMgA8JmTLhAcJJNQX4iUVYXqsiF5FwxiWU1BH5GDdTWaQd00EvJbPzFc";

const CURRENCY = "USD";

const fromDollarToCent = (amount) => parseInt(amount * 100);

const Checkout = ({ name, description, amount, tokens }) => {
  console.log(publishableKey);
  const appState = useContext(GlobalContext);
  const { hideTokenMenu, setNavTeamCredits } = appState;
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);

  const onToken = (amount, description, email) => (token) => {
    handleToggle();
    api
      .post("/api/v1/store/buy", {
        description,
        source: token.id,
        currency: CURRENCY,
        amount: amount,
        receipt_email: email,
      })
      .then(() => {
        handleToggle();
        hideTokenMenu();
        setNavTeamCredits((prev) => prev + tokens);
      })
      .catch(hideTokenMenu);
  };

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="success" />
      </Backdrop>
      <StripeCheckout
        name={name}
        description={`Your total is $${amount}`}
        image={TEAMMATEME_LOGO}
        panelLabel="Pay"
        amount={fromDollarToCent(amount)}
        token={onToken(fromDollarToCent(amount), description, user?.email)}
        currency={CURRENCY}
        stripeKey={publishableKey}
        zipCode
        email
        billingAddress
        allowRememberMe={true}
      >
        <button className="token--checkout-button">Pay with Credit Card</button>
      </StripeCheckout>
    </>
  );
};

export default Checkout;
