import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Dialog,
  Divider,
  IconButton,
  InputBase,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import api, { ChatBaseUrl } from "../../components/settings/api";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import ChatUsers from "../chat/ChatUsers";
import { IMAGES } from "../../utils/constants";
import FloatingUser from "./FloatingUser";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useMessageContextProvider } from "../context/MessageContext";
import { isObjectEmpty } from "../../utils/refactoredFunctions";
import { useAuth } from "../../components/context/auth";
import socketIOClient from "socket.io-client";

const useStyles = makeStyles((theme) => ({
  userList: {
    position: "fixed",
    bottom: "0vh",
    right: "0",
    width: "270px",
    maxWidth: "270px",
    maxHeight: "80vh",
    zIndex: 1004,
    boxShadow: "1px 1px 8px 1px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px 4px 0px 0px",
    marginLeft: theme.spacing(7.5),
    background: "#F4F5F2",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  notification: {
    position: "absolute",
    width: "18px",
    height: "18px",
    background: "#f91e01",
    color: "#fafafa",
    borderRadius: "50%",
    fontSize: "12px",
    top: "-7px",
    left: "-7px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  messageHeader: {
    display: "flex",
    padding: "16px",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    background: "#2eb790",
    color: "#ffffff",
    fontFamily: "Poppins",
    borderRadius: "5px 5px 0px 0px",
    marginRight: 1.5,
  },
  searchContainer: {
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "20px",
    background: "#F5F4F2",
    paddingBottom: "10px",
    [theme.breakpoints.down("md")]: {
      paddingRight: "20px",
      height: "33px",
    },
  },
  searchInput: {
    width: "235px",
    height: "23px",
    background: "#fff",
    alignItems: "center",
    display: "flex",
    borderRadius: "4px",
    "& svg": {
      fill: "#9EA8A5",
      fontSize: "16px",
      marginLeft: "8px",
      marginRight: "10px",
    },
    "& input": {
      padding: "0px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "100%",
    },
  },
  openUserList: {
    height: "auto",
  },
  floatingUser: {
    position: "fixed",
    right: "350px",
    bottom: "0px",
    zIndex: 1004,
    [theme.breakpoints.down("md")]: {
      position: "relative",
      right: "unset",
      bottom: "0px",
    },
  },
  userListContainer: {
    display: "none",
  },
  userListContainerDisplay: {
    display: "unset",
  },
  userListScrollable: {
    height: "auto",
    maxHeight: "calc(80vh - 65px)",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      maxHeight: "calc(100vh - 102px)",
    },
  },
  mobileHeaderChat: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.08)",
  },
  dialogRoot: {
    zIndex: "10000 !important",
  },
  paperScroll: {
    width: "100%",
    height: "100vh",
    margin: "0px",
  },
  containerClass: {
    height: "unset",
  },
  paperContainer: {
    overflowY: "unset",
  },
  chatMobileBanner: {
    background: "#2eb790",
    color: "white",
  },
  mobileChatIcon: {
    background: "white !important",
    height: "45px !important",
    width: "15px !imporatant",
    marginRight: 2,
  },
}));
const FloatingChat = () => {
  const classes = useStyles();
  const [allUsers, updateAllUsers] = useState([]);
  const [chatOpen, toggleChatOpen] = useState(false);
  const [, updateSearchContact] = useState();
  const [activeUserList, updateActiveUserList] = useState([]);
  const [searchedUserList, updateSearchedUserList] = useState([]);
  const [currentChatUser, updateCurrentChatUser] = useState();
  const [notificationUser, updateNotificationUser] = useState([]);
  const [notificationUserCount, updateNotificationUserCount] = useState(0);
  const [messageCount, updateMessageCount] = useState({});
  const notificationUserRef = useRef();
  const socketState = useRef();
  const [, updateComponent] = useState("");
  const {
    activeUserToMessage,
    updateActiveUserToMessage,
    isOpenMobileChat,
    toggleMobileChat,
    setHaseNewMessage,
  } = useMessageContextProvider();
  const { user } = useAuth();
  const [teamMembers, setTeamMembers] = useState([]);

  const aggrigateUniqueUsers = useCallback(
    (messages) => {
      const mappedUsers = messages.map((message) => {
        const pattern = {
          messageTime: message.time,
          roomID: [message.sender.id, message.receiver.id].sort().toString(),
        };
        if (message.receiver.id === user?.id)
          return {
            ...message.sender,
            ...pattern,
          };
        else return { ...message.receiver, ...pattern };
      });
      const id = "id";

      const uniqueUsers = [
        ...new Map(
          mappedUsers
            .sort(function (a, b) {
              return a.messageTime.localeCompare(b.messageTime);
            })
            .map((item) => [item[id], item])
        ).values(),
      ];
      return uniqueUsers;
    },
    [user?.id]
  );

  const getUserMessages = useCallback(() => {
    api
      .get(`/api/v1/chat/getUserMessages?userID=${user?.id}`)
      .then((res) => {
        const uniqueUsers = aggrigateUniqueUsers(res.data.messages);
        updateAllUsers(uniqueUsers);
        getHistoryUsers(uniqueUsers);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [aggrigateUniqueUsers, getHistoryUsers, user?.id]);

  useEffect(() => {
    toggleChatOpen(isOpenMobileChat);
    if (isOpenMobileChat) {
      readReceivedMessage();
    }
  }, [isOpenMobileChat, readReceivedMessage]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const unreadMessages = useCallback(
    () =>
      api.get("/api/v1/chat/getLastUnreadMessage").then((res) => {
        notificationUserRef.current = res.data?.map(
          (message) => message.senderID
        );
        updateNotificationUser(res.data);
        if (res.data.length > 0) {
          const countSet = new Set();
          for (const msg of res.data) {
            if (msg.senderID !== null) {
              countSet.add(msg.senderID);
            }
          }
          updateNotificationUserCount(countSet.size);
          setHaseNewMessage(true);
        }
      }),
    [setHaseNewMessage]
  );

  useEffect(() => {
    const socket = socketIOClient(ChatBaseUrl, {
      query: { chatID: user?.id },
      transports: ["websocket"],
      path: "/api/v1/socket.io",
    });
    socket.on("update_badge", () => {
      unreadMessages();
      getUserMessages();
    });
    socketState.current = socket;
    return () => {
      socketState.current.disconnect();
    };
  }, [getUserMessages, unreadMessages, user?.id]);

  useEffect(() => {
    if (user) {
      getUserMessages();
      unreadMessages();
    }
  }, [getUserMessages, unreadMessages, user]);

  const readReceivedMessage = useCallback(async () => {
    updateNotificationUser([]);
    setHaseNewMessage(false);
  }, [setHaseNewMessage]);

  const dateSort = (a, b) => {
    if (a.messageTime > b.messageTime) {
      return -1;
    }
    if (a.messageTime < b.messageTime) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (!isEmpty(activeUserToMessage)) {
      if (!activeUserList.some((user) => user.id === activeUserToMessage?.id)) {
        const tempUserList = [...activeUserList];
        if (isEmpty(user)) {
          tempUserList.push(user);
          updateCurrentChatUser(user);
          updateActiveUserList(tempUserList);
          searchedUserList?.sort(dateSort);
          updateSearchedUserList(tempUserList);
        } else {
          activeUserToMessage.roomID = [activeUserToMessage?.id, user?.id]
            .sort()
            .toString();
          updateAllUsers((users) => [...users, activeUserToMessage]);
          tempUserList.push(activeUserToMessage);
          updateCurrentChatUser(activeUserToMessage);
          updateActiveUserList(tempUserList);
          searchedUserList?.sort(dateSort);
          updateSearchedUserList(tempUserList);
        }
      } else {
        const tempUser = allUsers?.find(
          (user) => user.id === activeUserToMessage?.id
        );
        if (!isEmpty(tempUser)) {
          updateCurrentChatUser(tempUser);
        }
      }
    }
  }, [activeUserList, activeUserToMessage, allUsers, searchedUserList, user]);

  const getHistoryUsers = useCallback((userList) => {
    updateActiveUserList(userList);
    const tempUserList = userList?.sort(dateSort);
    updateSearchedUserList(tempUserList);
  }, []);

  const closeChat = () => {
    updateCurrentChatUser({});
    updateActiveUserToMessage({});
  };

  const fetchTeams = () => {
    api
      .get("/api/v1/teams/teams")
      .then((res) => {
        if (res.data.length > 0) {
          let teamMembers = [];
          for (let i = 0; i < res.data[0].length; i++) {
            teamMembers = teamMembers.concat(res.data[0][i].Team.Memberships)
          }
          for (let i = 0; i < res.data[1].length; i++) {
            teamMembers = teamMembers.concat(res.data[1][i].Team.Memberships)
          }
          for (let i = 0; i < res.data[2].length; i++) {
            teamMembers = teamMembers.concat(res.data[2][i].Team.Memberships)
          }

          teamMembers = [...new Map(teamMembers.map(item => [item.User["email"], item.User])).values()];

          teamMembers = teamMembers.filter((u) => {return u.email !== user.email})

          for (let i = 0; i < teamMembers.length; i++) {
            const tempUser = activeUserList.find(o => o.fullname === teamMembers[i].fullname)
            if (tempUser !== undefined) {
              teamMembers[i] = tempUser
            }

            if (!teamMembers[i].roomID) {
              teamMembers[i].roomID = teamMembers[i].id + "," + user.id;
            }
          }

          setTeamMembers(teamMembers);
        }
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          console.error(err);
        } else {
          throw err;
        }
      });
  };

  const handleUserSearch = (e) => {
    const value = e.target.value.trim();
    if (value.length === 0) {
      updateSearchedUserList(activeUserList);
    } else {
      let searched = teamMembers?.filter((user) =>
        user?.fullname?.toLowerCase()?.includes(value?.toLowerCase())
      );
      updateSearchedUserList(searched);
    }
    updateSearchContact(e.target.value);
  };

  const updateUserList = (roomID) => {
    const userIndex = activeUserList?.findIndex(
      (user) => user.roomID === roomID
    );
    activeUserList.push(...activeUserList.splice(0, userIndex));
    updateActiveUserList(activeUserList);
  };

  const removeNotificationUser = (data) => {
    const tempNotificationUser = [...notificationUserRef.current];
    const userIndex = tempNotificationUser.indexOf(data.id);
    if (userIndex !== -1) {
      tempNotificationUser.splice(userIndex, 1);
      notificationUserRef.current = tempNotificationUser;
      updateNotificationUser([]);
      if (tempNotificationUser.length > 0) {
        setHaseNewMessage(true);
      }
    }
  };

  const updateRemoveNotificationuser = (data) => {
    removeNotificationUser(data);
    updateActiveUserToMessage(data);
    const tempMessageCount = { ...messageCount };
    tempMessageCount[data?.id] = 0;
    updateMessageCount(tempMessageCount);
  };

  const renderUserList = () => {
    return (
      <div
        className={clsx(
          classes.userListContainer,
          chatOpen && classes.userListContainerDisplay
        )}
      >
        <Divider variant="fullWidth" component="div" />
        <div className={classes.searchContainer}>
          <InputBase
            className={classes.searchInput}
            onChange={handleUserSearch}
            inputProps={{
              className: "searchInputProps",
              placeholder: "Search Contacts",
            }}
            startAdornment={<SearchRoundedIcon />}
          />
        </div>
        <div className={classes.userListScrollable}>
          {searchedUserList?.length ? (
            <ChatUsers
              handleCallBack={(data) => {
                readReceivedMessage(data);
                updateRemoveNotificationuser(data);
              }}
              currentUserId={user?.id}
              chatUsers={searchedUserList}
              notificationUser={notificationUserRef.current ?? []}
              currentChatUserId={activeUserToMessage?.id}
              messageCount={messageCount}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const updateMessage = (userId, tempMessageCount) => {
    tempMessageCount[userId] = tempMessageCount[userId]
      ? tempMessageCount[userId] + 1
      : 1;
    updateMessageCount(tempMessageCount);
  };

  const updateAddNotificationUser = (userId, tempNotificationUser) => {
    if (!tempNotificationUser.includes(userId)) {
      tempNotificationUser.push(userId);
      notificationUserRef.current = tempNotificationUser;
      updateNotificationUser(tempNotificationUser);
      updateNotificationExtra(tempNotificationUser);
      updateComponent({});
    }
  };

  const updateNotificationExtra = (notificationUserList) => {
    updateNotificationUser(notificationUserList);
    if (notificationUserList.length > 0) {
      setHaseNewMessage(true);
    }
  };

  const renderChatModal = () => {
    return (
      <div className={clsx(classes.floatingUser, "floatingChat")}>
        <FloatingUser
          activeUserList={activeUserList}
          notificationUser={notificationUserRef.current || []}
          updateSearchedUserList={updateSearchedUserList}
          currentChatUser={currentChatUser}
          currentUser={user}
          onCloseUser={closeChat}
          allUsers={allUsers}
          updateActiveUserList={(data) => {
            updateActiveUserList(data);
            updateSearchedUserList(data);
          }}
          updateUserList={updateUserList}
          onNotification={(userId) =>
            updateAddNotificationUser(userId, notificationUserRef.current || [])
          }
          onRemoveUserFromNoti={updateRemoveNotificationuser}
          updateMessageCount={(userId) => updateMessage(userId, messageCount)}
        />
      </div>
    );
  };

  const handleClose = () => {
    toggleChatOpen(false);
  };

  return (
    <>
      {user && (
        <>
          <div
            className={clsx(
              classes.userList,
              chatOpen && classes.openUserList
            )}
          >
            {notificationUserCount ? (
              <span className={classes.notification}>
                {notificationUserCount}
              </span>
            ) : null}
            <div
              className={classes.messageHeader}
              onClick={() => toggleChatOpen(!chatOpen)}
              onKeyDown={() => toggleChatOpen(!chatOpen)}
              role="button"
              tabIndex={0}
              aria-label="Toggle Chat"
            >
              <div>Messages</div>
              <div>
                {chatOpen ? (
                  <ExpandMoreRoundedIcon />
                ) : (
                  <KeyboardArrowUpRoundedIcon />
                )}
              </div>
            </div>
            <div>{renderUserList()}</div>
          </div>
          {renderChatModal()}
        </>
      )}
      {isOpenMobileChat && (
        <Dialog
          open={isOpenMobileChat}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          classes={{
            root: classes.dialogRoot,
            paperScrollPaper: classes.paperScroll,
            container: classes.containerClass,
            paper: classes.paperContainer,
          }}
        >
          {isObjectEmpty(currentChatUser) ||
          currentChatUser == null ||
          (currentChatUser == undefined && currentChatUser == {}) ? (
            <div>
              <div
                className={clsx(
                  classes.mobileHeaderChat,
                  classes.chatMobileBanner
                )}
              >
                <div>
                  <IconButton
                    onClick={() => toggleMobileChat(false)}
                    size="large"
                    aria-label="close"
                  >
                    <CloseRoundedIcon color="secondary" />
                  </IconButton>
                </div>
                <div>Messages</div>
                <div>
                  <IconButton className={classes.mobileChatIcon} size="large">
                    <img src={IMAGES.edit} alt="expand-chat-window-button" />
                  </IconButton>
                </div>
              </div>
              {renderUserList()}
            </div>
          ) : (
            renderChatModal()
          )}
        </Dialog>
      )}
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default FloatingChat;
