import React from "react";
import { Avatar, ListItem, ListItemAvatar } from "@mui/material";
import { makeStyles } from '@mui/styles';
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  messageConatiner: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    padding: "0px 16px 8px",
  },
  avatar: {
    marginTop: theme.spacing(0),
    display: "flex",
  },
  sendByMeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "50%",
  },
  sendByThemContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
    alignItems: "flex-start",
  },
  sendByMe: {
    background: theme.palette.teamMate.primary.blue,
    maxWidth: "100%",
    padding: `${theme.spacing(1.25)} ${theme.spacing(0.875)}`,
    color: theme.palette.white,
    borderRadius: theme.spacing(0.625),
    marginBlockStart: theme.spacing(0),
    marginBlockEnd: theme.spacing(0),
    wordBreak: "break-word",
    "& > p": {
      marginBlockStart: theme.spacing(0),
      marginBlockEnd: theme.spacing(0),
      fontSize: "14px",
    },
    "& > ul": {
      marginBlockStart: theme.spacing(0),
      marginBlockEnd: theme.spacing(0),
      paddingInlineStart: theme.spacing(2),
      fontSize: "14px",
    },
    "& > ol": {
      marginBlockStart: theme.spacing(0),
      marginBlockEnd: theme.spacing(0),
      paddingInlineStart: theme.spacing(2),
      fontSize: "14px",
    },
  },
  sendByThem: {
    background: theme.palette.teamMate.darkGrey,
    maxWidth: "100%",
    padding: `${theme.spacing(1.25)} ${theme.spacing(0.875)}`,
    color: theme.palette.black,
    borderRadius: theme.spacing(0.625),
    marginBlockStart: theme.spacing(0),
    marginBlockEnd: theme.spacing(0),
    wordBreak: "break-word",
    "& > p": {
      marginBlockStart: theme.spacing(0),
      marginBlockEnd: theme.spacing(0),
      fontSize: "14px",
    },
    "& > ul": {
      marginBlockStart: theme.spacing(0),
      marginBlockEnd: theme.spacing(0),
      paddingInlineStart: theme.spacing(2),
      fontSize: "14px",
    },
    "& > ol": {
      marginBlockStart: theme.spacing(0),
      marginBlockEnd: theme.spacing(0),
      paddingInlineStart: theme.spacing(2),
      fontSize: "14px",
    },
  },
  horizontalLine: {
    color: "#E0E0E0",
    alignItems: "center",
    display: "flex",
    flexWrap: "no-wrap",
    margin: "2em 0em",
    width: "100%",
    justifyContent: "center",
    "& hr": {
      width: "15%",
      margin: "0px",
      borderTop: "1px solid",
    },
    "& span": {
      margin: "0em .5em",
      fontSize: ".9rem",
      color: "#9F9F9F",
    },
  },
}));

const ChatMessage = ({
  messageContent,
  dateTime,
  avatarRequired,
  currentUserId,
  senderPic,
  receiverPic,
}) => {
  const classes = useStyles();
  const { senderID, message, receiverID } = messageContent;
  const sendByMe = currentUserId === senderID;

  const renderDateTime = () => {
    const dateTimeValue = dateTime();
    if (dateTimeValue !== null) {
      return (
        <ListItem>
          <div className={classes.horizontalLine}>
            <hr />
            <span>{dateTimeValue}</span>
            <hr />
          </div>
        </ListItem>
      );
    }
  };

  return (
    <>
      {renderDateTime()}
      <ListItem
        alignItems="flex-start"
        className={sendByMe ? classes.messageConatiner : ""}
        role="listitem"
      >
        <ListItemAvatar
          className={classes.avatar}
          style={{
            justifyContent: sendByMe ? "flex-end" : "flex-start",
            minWidth: "48px",
          }}
          aria-hidden={!avatarRequired}
        >
          {avatarRequired ? (
            <Avatar
              alt="Remy Sharp"
              src={!sendByMe ? senderPic : receiverPic}
            />
          ) : (
            <></>
          )}
        </ListItemAvatar>
        <div
          className={
            sendByMe ? classes.sendByMeContainer : classes.sendByThemContainer
          }
          aria-label={sendByMe ? "Message sent by me" : "Message received"}
        >
          <div className={sendByMe ? classes.sendByMe : classes.sendByThem} aria-live="polite">
            {parse(message)}
          </div>
        </div>
      </ListItem>
    </>
  );
};

export default ChatMessage;
